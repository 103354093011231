import Container from '../../layout/container/Container';
import React, { FC } from 'react';

type SkeletonUbosInProgressLoaderProps = {
  items?: number;
};
const SkeletonUbosInProgressLoader: FC<SkeletonUbosInProgressLoaderProps> = ({ items = 3 }) => (
  <div className="animate-pulse">
    <div className="w-2/5 h-6 my-8 bg-primary-200 dark:bg-primary-800 rounded-2xl" />
    <div className="h-4 w-32 bg-primary-200 dark:bg-primary-800 rounded-lg mt-10 mb-8" />
    <div className="flex p-4 my-4.5 justify-between">
      <div className="h-5 w-1/4 rounded-xl bg-primary-200 dark:bg-primary-800" />
      <div className="flex gap-1 text-x-small items-center">
        <div className="h-3 w-5 rounded-xl bg-primary-200 dark:bg-primary-800" />
        <div className="h-3 w-12 rounded-xl bg-primary-200 dark:bg-primary-800" />
      </div>
    </div>
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-1.5 px-3 py-2 border-2 border-primary-500 rounded-full self-start w-auto mb-6">
        <div className="w-5 h-5 bg-primary-200 dark:bg-primary-800 rounded-full" />
        <div className="h-3 w-52 bg-primary-200 dark:bg-primary-800 rounded-lg" />
      </div>
      <div className="h-4 w-10 bg-primary-200 dark:bg-primary-800 rounded-lg" />
      {Array.from({ length: items }, (_, index) => (
        <div key={index} className="flex items-center">
          <Container
            className="flex items-center justify-between flex-grow"
            roundedClass="rounded-2xl"
          >
            <div className="h-3 w-1/3 bg-primary-200 dark:bg-primary-800 rounded-lg" />
            <div className="px-2 py-0.5 bg-primary-200 dark:bg-primary-800 rounded-lg w-20 h-4" />
          </Container>
          <div className="w-6 h-6 bg-primary-200 dark:bg-primary-800 ml-4 rounded-full" />
        </div>
      ))}
    </div>
  </div>
);

export default SkeletonUbosInProgressLoader;
