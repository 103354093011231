import PrimaryHeader from '../../text/primary-header/PrimaryHeader';

const SkeletonProvideKvkLoader = () => (
  <div className="animate-pulse flex flex-col gap-6">
    <PrimaryHeader className="w-1/2 h-5 bg-primary-200 dark:bg-primary-800 rounded-xl" />
    <div className="flex flex-col gap-2">
      <div className="w-11/12 h-3 bg-primary-200 dark:bg-primary-800 rounded-xl" />
      <div className="md:hidden w-11/12 h-3 bg-primary-200 dark:bg-primary-800 rounded-xl" />
      <div className="w-2/3 h-3 bg-primary-200 dark:bg-primary-800 rounded-xl" />
    </div>
    <div className="w-1/3 h-4 bg-primary-200 dark:bg-primary-800 rounded-xl" />
    <div className="w-full h-15 bg-biz-gray-200 dark:bg-biz-gray-700 rounded-lg-plus" />
  </div>
);
export default SkeletonProvideKvkLoader;
