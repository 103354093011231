import ais from './ais.json';
import common from './common.json';
import invoiceFinancing from './invoiceFinancing.json';
import orchestrator from './orchestrator.json';
import accountingHub from './accountingHub.json';
import genericErrors from './genericErrors.json';
import cdd from './cdd.json';

export default {
  ais,
  common,
  invoiceFinancing,
  orchestrator,
  accountingHub,
  genericErrors,
  cdd,
};
