import { FlowLayout } from 'src/atoms/layout/menu-page';
import { useNavigate } from 'react-router';
import React, { useState } from 'react';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import AlertDialog from 'src/organisms/dialog/AlertDialog';
import AddButton from 'src/atoms/buttons/add-button/AddButton';
import Label from 'src/atoms/text/label/Label';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import Container from 'src/atoms/layout/container/Container';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'src/atoms/buttons/tertiary-button/TertiaryButton';
import Paths from 'src/tokens/Paths';
import { useDeleteUbo, useGetCustomers, useGetUbos } from 'src/types/bizcuitApi';
import { useLocation } from 'react-router-dom';
import { parseQueryParamsString } from 'src/helpers';
import { useErrorDialog } from 'src/hooks';
import { useOrchestrator } from 'src/hooks/useOrchestrator';
import SkeletonUbosInProgressLoader from 'src/atoms/loaders/skeleton-loader/SkeletonUbosInProgressLoader';

const UbosInProgress = () => {
  const orchestrator = useOrchestrator();
  const errorDialog = useErrorDialog();
  const location = useLocation();
  const { dynamicId } = parseQueryParamsString(location.search);
  const { data, loading, error, refetch } = useGetUbos({
    variables: { customerId: dynamicId },
    fetchPolicy: 'network-only',
  });
  const [showAlert, setShowAlert] = useState({
    whatIsUbo: false,
    nobodyHasMoreThan25Percent: false,
    deleteUbo: false,
  });
  const [selectedUbo, setSelectedUbo] = useState<string | null>(null);
  const [deleting, setDeleting] = useState(false);
  const [deleteUbo, { loading: deleteUboLoading, error: deleteUboError }] = useDeleteUbo();
  const { data: customersData, loading: customersDataLoading } = useGetCustomers();
  const navigate = useNavigate();
  const { t } = useTranslation(['cdd', 'common']);

  const actionId = 'verify-ubo';

  if (error || deleteUboError) {
    errorDialog.open();
  }

  const customer = customersData?.getCustomers.find((customer) => customer.id === dynamicId);
  //TODO: Replace with actual  ubo type
  const uboTranslation = 'ubo';

  let totalOwnershipPercentage = 0;
  let totalVotingPercentage = 0;

  data?.getUbos?.forEach((ubo) => {
    totalVotingPercentage += ubo.votingPercentage ?? 0;
    totalOwnershipPercentage += ubo.ownershipPercentage ?? 0;
  });

  const handleDelete = async () => {
    setDeleting(true);
    if (selectedUbo && !deleteUboLoading && !deleteUboError) {
      await deleteUbo({ variables: { customerId: dynamicId, uboId: selectedUbo } });
    }

    await refetch();
    setSelectedUbo(null);
    setDeleting(false);
  };

  const handleAddOrUpdate = (uboId?: string) => {
    navigate(
      `${Paths.ais.root}${Paths.ais.segments.cdd.root}${
        Paths.ais.segments.cdd.segments.uboform
      }?dynamicId=${dynamicId}${uboId ? `&uboId=${uboId}` : ''}`,
    );
  };

  const handleConfirmUbo = async () => {
    await orchestrator.methods.finishFlowAction({ actionId, dynamicId });
  };

  const reviewUbosNeeded = data?.getUbos?.some(
    (ubo) => ubo.ownershipPercentage === null || ubo.votingPercentage === null,
  );
  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex pb-6 pt-4 items-center w-full flex-wrap flex-col">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          <SecondaryButton
            underline
            onClick={() =>
              setShowAlert((prevState) => ({ ...prevState, nobodyHasMoreThan25Percent: true }))
            }
          >
            {t(`cdd:ubosInProgress.${uboTranslation}.secondaryButton`)}
          </SecondaryButton>
          <PrimaryButton
            className="md:w-80"
            isDisabled={
              loading ||
              totalOwnershipPercentage > 100 ||
              totalVotingPercentage > 100 ||
              reviewUbosNeeded
            }
            onClick={handleConfirmUbo}
            showSpinner={orchestrator.loading.finishFlowAction}
          >
            {t(`cdd:ubosInProgress.${uboTranslation}.primaryButton`)}
          </PrimaryButton>
          {totalVotingPercentage > 100 || totalOwnershipPercentage > 100 ? (
            <>
              {totalVotingPercentage > 100 && (
                <p className="text-small-loose md:text-x-small-loose text-center text-primary-900 dark:text-white mt-1">
                  {t('cdd:ubosInProgress.ubo.uboTotalVotingRightsExceeds')}
                </p>
              )}
              {totalOwnershipPercentage > 100 && (
                <p className="text-small-loose md:text-x-small-loose text-center text-primary-900 dark:text-white mt-1">
                  {t('cdd:ubosInProgress.ubo.uboTotalOwnershipExceeds')}
                </p>
              )}
            </>
          ) : (
            <p className="text-small-loose md:text-x-small-loose text-center text-primary-900 dark:text-white mt-1">
              {t('cdd:ubosInProgress.ubo.uboWarning')}
            </p>
          )}
        </footer>
      }
    >
      {customersDataLoading || loading ? (
        <SkeletonUbosInProgressLoader items={2} />
      ) : (
        <>
          <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
            {t(`cdd:ubosInProgress.${uboTranslation}.primaryHeader`)}
          </h1>
          <TertiaryButton
            className="mb-6"
            color="gray-800"
            underline
            onClick={() => setShowAlert((prevState) => ({ ...prevState, whatIsUbo: true }))}
          >
            {t(`cdd:whatIsUboDialog.${uboTranslation}.title`)}
          </TertiaryButton>
          <div className="flex p-4 justify-between mb-6">
            <p className="text-medium font-weight-500">{customer?.name}</p>
            <div className="flex gap-1 text-x-small items-center">
              <p className="font-weight-500">{t('common:chamberOfCommerceShortHand')}</p>
              <p>{customer?.kvkNumber}</p>
            </div>
          </div>
          <AddButton className="self-start w-auto mb-6" onClick={() => handleAddOrUpdate()}>
            {t(`cdd:ubosInProgress.${uboTranslation}.addUbo`)}
          </AddButton>
          <p className="text-x-small font-weight-500 text-biz-gray-600 dark:text-white mb-2">
            {t(`cdd:ubos.${uboTranslation}`)}
          </p>
          <div className="flex flex-col gap-2">
            {data?.getUbos?.map((ubo) => (
              <div className="flex items-center" key={ubo.id}>
                <Container
                  className="flex items-center justify-between flex-grow hover:cursor-pointer"
                  roundedClass="rounded-2xl"
                  onClick={() => {
                    handleAddOrUpdate(ubo.id);
                  }}
                >
                  <p className="text-small font-weight-500 text-gray-800 dark:text-white truncate">
                    {`${ubo.firstName} ${ubo.lastName}`}
                  </p>
                  {ubo.ownershipPercentage === null || ubo.votingPercentage === null ? (
                    <Label className="text-nowrap ml-4" type="yellow">
                      {t('common:fillIn')}
                    </Label>
                  ) : (
                    <Label className="text-nowrap ml-4">{t('common:complete')}</Label>
                  )}
                </Container>
                <div
                  className="cursor-pointer ml-4"
                  onClick={() => {
                    setShowAlert((prevState) => ({ ...prevState, deleteUbo: true }));
                    setSelectedUbo(ubo.id);
                  }}
                >
                  {deleting && selectedUbo === ubo.id ? (
                    <BizcuitIcon icon="spinner" className="animate-spin" />
                  ) : (
                    <BizcuitIcon
                      icon="trash"
                      className="text-warm-grey-800 dark:text-white"
                      size={24}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <AlertDialog
        isVisible={showAlert.whatIsUbo}
        onClose={() =>
          setShowAlert((prevstate) => ({ ...prevstate, whatIsUbo: !showAlert.whatIsUbo }))
        }
        alertDialogTexts={t(`cdd:whatIsUboDialog.${uboTranslation}`, { returnObjects: true })}
      />
      <AlertDialog
        isVisible={showAlert.deleteUbo}
        onConfirm={() => {
          setShowAlert((prevstate) => ({
            ...prevstate,
            deleteUbo: !prevstate.deleteUbo,
          }));
          handleDelete();
        }}
        onClose={() => {
          setShowAlert((prevstate) => ({
            ...prevstate,
            deleteUbo: !prevstate.deleteUbo,
          }));
          setSelectedUbo(null);
        }}
        alertDialogTexts={t('cdd:deleteUboDialog', { returnObjects: true })}
      />
      <AlertDialog
        isVisible={showAlert.nobodyHasMoreThan25Percent}
        onClose={() =>
          setShowAlert((prevState) => ({ ...prevState, nobodyHasMoreThan25Percent: false }))
        }
        alertDialogTexts={t('cdd:processOverview.alertDialog', { returnObjects: true })}
        primaryColorContinueButton
      />
    </FlowLayout>
  );
};

export default UbosInProgress;
