import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KvkResultContainer from 'src/molecules/layout/kvk-result-container/KvkResultContainer';
import TextInput from 'src/atoms/inputs/text-input/TextInput';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { TextColor } from 'src/atoms/text/text/Text.types';
import { CompanyItem, useSearchCompaniesLazyQuery } from 'src/types/bizcuitApi';
import KvKSearchProps from './KvKSearch.types';
import { PageTestIdKeys } from 'src/tokens';
import { isNotNull } from 'src/utils';

const KvkSearch: FC<KvKSearchProps> = ({
  onSelected,
  companyCount,
  initialSearch,
  showCreateAccount = true,
}: KvKSearchProps) => {
  const { t } = useTranslation('invoiceFinancing');
  const [search, setSearch] = useState(initialSearch?.trim() ?? '');
  const [selectedCompany, setSelectedCompany] = useState<CompanyItem>();
  const [noResults, setNoResults] = useState(false);
  const [searchResults, setSearchResults] = useState<CompanyItem[] | null>(null);
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const timerRef = useRef<number | undefined>();
  const MIN_SEARCH_LENGTH = 3;
  const isKvkNumber = (value: string): boolean => {
    // KvK states that valid numbers are always 8 digits
    // https://www.kvk.nl/english/registration/kvk-number-all-you-need-to-know/
    return /\d{8}/.test(value);
  };
  const [companySearch, { loading }] = useSearchCompaniesLazyQuery({
    variables: {
      search: isKvkNumber(search) ? null : search,
      kvkNumber: isKvkNumber(search) ? search : null,
    },
    onCompleted(data) {
      const filteredItems = data?.searchCompanies?.items?.filter(isNotNull) ?? [];
      setSearchResults(filteredItems);
      setNoResults(filteredItems.length === 0);

      if (filteredItems.length !== 1) return;

      handleKvkContainerClick(filteredItems[0]);
    },
    onError(error) {
      if (!error.message?.startsWith('The operation was aborted')) {
        setNoResults(true);
      }
    },
  });

  const showCreateBox = !loading && showCreateAccount && search.length >= MIN_SEARCH_LENGTH;

  useEffect(() => {
    if (initialSearch?.trim() && initialSearch.length >= MIN_SEARCH_LENGTH) {
      companySearch();
    }
  }, [initialSearch, companySearch]);

  useEffect(() => {
    if (loading) return;
    if (companyCount) companyCount(searchResults?.length ?? 0);
  }, [companyCount, searchResults, loading]);

  const onInputChange = useCallback(
    (value: string) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        abortControllerRef.current.abort();
      }

      setSearch(value);
      setNoResults(false);
      setSearchResults(null);

      if (value.length >= MIN_SEARCH_LENGTH) {
        abortControllerRef.current = new AbortController();
        timerRef.current = window.setTimeout(() => {
          companySearch({
            context: {
              fetchOptions: {
                signal: abortControllerRef.current.signal,
              },
            },
          });
        }, 500);
      }
    },
    [companySearch],
  );

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const searchResultSuffix = {
    element: (
      <BizcuitIcon icon="arrow-right" className="w-6 h-6" primaryColor={TextColor.bizGreen500} />
    ),
  };

  const handleKvkContainerClick = useCallback(
    (company: CompanyItem) => {
      setSelectedCompany(company);
      onSelected(company);
    },
    [onSelected],
  );

  if (selectedCompany) {
    return (
      <KvkResultContainer
        subText={selectedCompany.street}
        companyName={selectedCompany.tradeName}
        cocNumber={selectedCompany.kvkNumber}
        suffix={{
          element: (
            <BizcuitIcon icon="x-circle" primaryColor={TextColor.bizRed500} className="w-6 h-6" />
          ),
          onClick: () => {
            setSelectedCompany(undefined);
            onSelected(undefined);
          },
        }}
        shadowClass="shadow-sm"
        className="rounded-lg-plus"
      />
    );
  }

  return (
    <div className="flex flex-col">
      <TextInput
        data-testid="search-input"
        testId={PageTestIdKeys.enterCompany.searchInput}
        label={t('kvk.label')}
        name="Bedrijf"
        placeHolder={t('kvk.placeholder')}
        subText={t('kvk.sub_text')}
        prefixImgSrc="search.svg"
        clearFieldSuffix
        onChange={onInputChange}
        value={search}
        focusOnRender
        loading={loading}
      />
      {search.length > 0 && (
        <div
          className={`max-h-80 overflow-y-scroll overflow-x-auto rounded-lg-plus shadow-md bg-white dark:bg-biz-gray-800 mt-1 ${
            showCreateBox ? 'scrollbar-hide' : ''
          }`}
        >
          {searchResults &&
            searchResults.map(
              (item, index) =>
                item && (
                  <KvkResultContainer
                    key={`${item.kvkNumber}-${index}`}
                    onClick={() => handleKvkContainerClick(item)}
                    subText={item.street && item.city ? `${item.street}, ${item.city}` : ``}
                    companyName={item.tradeName}
                    cocNumber={item.kvkNumber}
                    suffix={searchResultSuffix}
                  />
                ),
            )}
          {showCreateBox && (
            <KvkResultContainer
              onClick={() =>
                onSelected({
                  kvkNumber: '',
                  branchNumber: '',
                  type: '',
                  tradeName: search,
                  links: [],
                })
              }
              subText={t('kvk.createAccountUnderThisName')}
              companyName={search}
              suffix={searchResultSuffix}
            />
          )}
        </div>
      )}
      {noResults && (
        <div
          role="alert"
          aria-live="polite"
          className="p-4 mt-1 flex justify-start  text-biz-gray-800 dark:text-biz-gray-200 dark:bg-biz-gray-800 shadow-sm rounded-lg-plus"
        >
          <div className="self-center flex-none mr-4">
            <BizcuitIcon icon="alert" aria-hidden="true" />
          </div>
          {t('kvk.noResults')}
        </div>
      )}
    </div>
  );
};

export default KvkSearch;
