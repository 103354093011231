import {
  mockAdministrations,
  mockBankAccounts,
  mockUserData,
  mockVerifiedUserData,
} from '../MockData';
import { DemoNames, UserPayloadData, VerifiedUserPayloadData } from '../OrchestratorFlowDemo.types';
import { OrchestratorFlowDemoState } from '../useOrchestratorFlowDemo';

type CreateUserDataParams = Pick<
  OrchestratorFlowDemoState,
  'demoPartner' | 'flowId' | 'termsAccepted' | 'email' | 'externalId'
>;

const createUserData = ({
  demoPartner,
  flowId,
  termsAccepted,
  email,
  externalId,
}: CreateUserDataParams) => {
  const requireVerifiedUser = [
    DemoNames.AsnBoekhoudkoppelingAcc,
    DemoNames.AsnBoekhoudkoppelingProd,
    DemoNames.RegioBankBoekhoudkoppelingAcc,
    DemoNames.RegioBankBoekhoudkoppelingProd,
    DemoNames.SnsBoekhoudkoppelingAcc,
    DemoNames.SnsBoekhoudkoppelingProd,
    DemoNames.ConnectAccountingSystemMollieApAr,
  ].includes(demoPartner);
  const payload = requireVerifiedUser
    ? ({ ...mockVerifiedUserData } as VerifiedUserPayloadData)
    : { user: { ...mockUserData } as UserPayloadData };

  payload.user.partner_reference = flowId + '-reference';
  payload.user.terms_accepted = termsAccepted;

  if (email) payload.user.email = email;
  payload.user.external_id = externalId;

  if (demoPartner === DemoNames.ConnectAccountingSystemMollieApAr) {
    payload.administration = mockAdministrations[2];
    payload.bank_account = mockBankAccounts[3];
  }

  return payload;
};

export default createUserData;
