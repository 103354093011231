import React, { FC } from 'react';
import Container from '../../layout/container/Container';
import SkeletonLoaderProps from './SkeletonLoader.types';

const SkeletonLoader: FC<SkeletonLoaderProps> = (props: SkeletonLoaderProps) => (
  <div className={props?.wrapperClassName ?? ''}>
    {Array.from(Array(props.items || 3)).map((_, index) => (
      <Container
        key={index}
        className="border border-primary-200 dark:border-primary-800 flex mb-2 animate-pulse"
      >
        <div className="w-6 h-6 rounded-full bg-primary-200 dark:bg-primary-800 mr-4 flex-none" />
        <div className="flex-grow">
          <div className="w-2/3 h-2 bg-primary-200 dark:bg-primary-800 rounded-2xl mb-2" />
          <div className="w-1/2 h-2 bg-primary-200 dark:bg-primary-800 rounded-2xl" />
        </div>
      </Container>
    ))}
  </div>
);

export default SkeletonLoader;
