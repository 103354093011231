import { FlowLayout } from 'src/atoms/layout/menu-page';
import { useNavigate, useLocation } from 'react-router';
import React, { useState } from 'react';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import AlertDialog from 'src/organisms/dialog/AlertDialog';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'src/atoms/buttons/tertiary-button/TertiaryButton';
import Paths from 'src/tokens/Paths';
import { getTranslation, parseQueryParamsString } from 'src/helpers';
import RadioContainer from 'src/atoms/inputs/radio-container/RadioContainer';
import { useCreateOrUpdateUbo, useGetCustomersAndUbos } from 'src/types/bizcuitApi';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import SkeletonCopyUboFromCompanyLoader from 'src/atoms/loaders/skeleton-loader/SkeletonCopyUboFromCompanyLoader';
import { useErrorDialog } from 'src/hooks';

const UbosFromAnotherCompany = () => {
  const [showWhatIsUbo, setShowWhatIsUbo] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { dynamicId } = parseQueryParamsString(location.search);
  const { t } = useTranslation(['cdd', 'common']);
  const [createOrUpdateUbo, { loading: createOrUpdateUboLoading, error: createOrUpdateUboError }] =
    useCreateOrUpdateUbo();
  const {
    data,
    loading: getCustomersAndUbosLoading,
    error: getCustomersAndUbosError,
  } = useGetCustomersAndUbos({ fetchPolicy: 'cache-and-network' });
  const errorDialog = useErrorDialog();

  if (getCustomersAndUbosError || createOrUpdateUboError) {
    errorDialog.open();
  }

  const customer = data?.getCustomers.find((customer) => customer.id === dynamicId);
  //TODO: Replace with actual ubo type
  const uboTranslation = 'ubo';

  const handleRefuse = () => {
    // TODO: navigate to ubosFromDirector when implemented
    navigate(
      `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.ubosInProgress}?dynamicId=${dynamicId}`,
    );
  };
  const handleProceed = async () => {
    const ubosToCopy = data?.getCustomers.find((customer) => customer.id === selectedCompany)?.ubos;

    if (!ubosToCopy) return;

    const createUboPromises = ubosToCopy.map((ubo) =>
      createOrUpdateUbo({
        variables: {
          customerId: dynamicId,
          isPseudoUbo: uboTranslation !== 'ubo',
          uboDetails: {
            firstName: ubo.firstName || '',
            lastName: ubo.lastName || '',
            dateOfBirth: ubo.dateOfBirth || '',
            nationality: ubo.nationality || '',
            countryOfResidence: ubo.countryOfResidence || '',
          },
        },
      }),
    );
    await Promise.all(createUboPromises);

    navigate(
      `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.ubosInProgress}?dynamicId=${dynamicId}`,
    );
  };

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex p-4 items-center w-full flex-wrap flex-col">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          <SecondaryButton underline onClick={() => handleRefuse()}>
            {t('common:button.noThankYou')}
          </SecondaryButton>
          <PrimaryButton
            className="md:w-80"
            onClick={() => handleProceed()}
            isDisabled={!selectedCompany || createOrUpdateUboLoading}
          >
            {createOrUpdateUboLoading ? (
              <BizcuitIcon icon={'spinner'} />
            ) : (
              t('common:button.proceed')
            )}
          </PrimaryButton>
        </footer>
      }
    >
      {getCustomersAndUbosLoading ? (
        <SkeletonCopyUboFromCompanyLoader items={2} />
      ) : (
        <>
          <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
            {t(`cdd:ubosFromAnotherCompany.${uboTranslation}.primaryHeader`)}
          </h1>
          <div className="flex flex-col">
            <p className="text-medium-loose mb-2">
              {getTranslation(`cdd:ubosFromAnotherCompany.${uboTranslation}.description`)}
            </p>
            <TertiaryButton
              className="mb-6"
              color="gray-800"
              underline
              onClick={() => setShowWhatIsUbo(true)}
            >
              {t(`cdd:whatIsUboDialog.${uboTranslation}.title`)}
            </TertiaryButton>
            <div className="flex p-4 justify-between mb-6">
              <p className="text-medium font-weight-500">{customer?.name}</p>
              <div className="flex gap-1 text-x-small items-center">
                <p className="font-weight-500">{t('common:chamberOfCommerceShortHand')}</p>
                <p>{customer?.kvkNumber}</p>
              </div>
            </div>
            <p className="text-x-small font-weight-500 text-biz-gray-600 dark:text-white mb-2">
              {t('common:companies')}
            </p>
            <div className="grid md:grid-cols-2 gap-2">
              {data?.getCustomers
                .filter((customersAndUbos) => customersAndUbos.ubos.length > 0)
                .map((customerAndUbo) => (
                  <RadioContainer
                    key={customerAndUbo.id}
                    value={customerAndUbo.name}
                    name={customerAndUbo.name}
                    onSelected={() => setSelectedCompany(customerAndUbo.id)}
                    isChecked={selectedCompany === customerAndUbo.id}
                  >
                    <div className="flex flex-col gap-1">
                      <p className="text-small font-weight-500">{customerAndUbo.name}</p>
                      {customerAndUbo.ubos.map((ubo) => (
                        <p className="text-x-small" key={ubo.id}>
                          {ubo.firstName} {ubo.lastName}
                        </p>
                      ))}
                    </div>
                  </RadioContainer>
                ))}
            </div>
          </div>
        </>
      )}
      <AlertDialog
        isVisible={showWhatIsUbo}
        onClose={() => setShowWhatIsUbo(!showWhatIsUbo)}
        alertDialogTexts={t(`cdd:whatIsUboDialog.${uboTranslation}`, { returnObjects: true })}
        showContactSupport
      />
    </FlowLayout>
  );
};

export default UbosFromAnotherCompany;
