import React from 'react';

const SkeletonCompanyLoader = () => (
  <div className="flex p-4 mb-6 justify-between animate-pulse">
    <div className="h-5 w-1/4 rounded-xl bg-primary-200 dark:bg-primary-800" />
    <div className="flex gap-1 text-x-small items-center">
      <div className="h-3 w-5 rounded-xl bg-primary-200 dark:bg-primary-800" />
      <div className="h-3 w-12 rounded-xl bg-primary-200 dark:bg-primary-800" />
    </div>
  </div>
);

export default SkeletonCompanyLoader;
