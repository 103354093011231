import React, { memo, ReactElement } from 'react';
import { CgProfile } from 'react-icons/cg';
import { FaCamera, FaRegAddressCard } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa6';
import { RiArrowDownSLine } from 'react-icons/ri';
import {
  FiArrowLeft,
  FiArrowRight,
  FiCalendar,
  FiChevronDown,
  FiChevronUp,
  FiCreditCard,
  FiDownload,
  FiEdit2,
  FiFileText,
  FiFolder,
  FiLink,
  FiLock,
  FiMinus,
  FiPlusCircle,
  FiUser,
  FiX,
  FiXCircle,
  FiCheckCircle,
  FiAlertTriangle,
  FiBell,
  FiTrash2,
} from 'react-icons/fi';
import {
  IoMdCloseCircle,
  IoMdInformationCircleOutline,
  IoMdHelpCircleOutline,
} from 'react-icons/io';
import { IoSettingsSharp } from 'react-icons/io5';
import {
  MdAccountBalanceWallet,
  MdApps,
  MdCheckCircle,
  MdCheck,
  MdContacts,
  MdDescription,
  MdEuro,
  MdFastForward,
  MdFingerprint,
  MdHome,
  MdOutlineDynamicFeed,
  MdPayment,
  MdPayments,
  MdSend,
  MdTimer,
} from 'react-icons/md';
import IconContext from 'src/contexts/icon-context/IconContext';
import IconContextProps from 'src/contexts/icon-context/IconContext.types';
import {
  Desktop,
  Email,
  ErrorLoading,
  Loading,
  Succeeded,
  Support,
  HandEuro,
  BizcuitLogoTransparent,
  Hourglass,
} from './svg';
import Alert from './svg/Alert';
import SvgFaceId from './svg/FaceId';
import SvgHistory from './svg/History';
import SvgIdin from './svg/Idin';
import SvgSpinner from './svg/Spinner';
import Hix from './svg/Hix';
import SvgExportDocument from './svg/ExportDocument';

const IconMap = {
  documents: <MdDescription />,
  home: <MdHome />,
  apps: <MdApps />,
  balance: <MdAccountBalanceWallet />,
  scan: <FaCamera />,
  send: <MdSend />,
  timer: <MdTimer />,
  'fast-forward': <MdFastForward />,
  settings: <IoSettingsSharp />,
  contacts: <MdContacts />,
  loading: <Loading />,
  succeeded: <Succeeded />,
  'rabo-invoice-financing': <HandEuro />,
  euro: <MdEuro />,
  support: <Support />,
  'check-circle': <MdCheckCircle />,
  check: <MdCheck />,
  folder: <FiFolder />,
  payment: <MdPayment />,
  payments: <MdPayments />,
  profile: <CgProfile />,
  'x-circle': <FiXCircle />,
  'dynamic-feed': <MdOutlineDynamicFeed />,
  'close-circle-filled': <IoMdCloseCircle />,
  calendar: <FiCalendar />,
  'chevron-down': <FiChevronDown />,
  'chevron-up': <FiChevronUp />,
  padlock: <FiLock />,
  'arrow-left': <FiArrowLeft />,
  'arrow-right': <FiArrowRight />,
  email: <Email />,
  description: <MdDescription />,
  id: <FaRegAddressCard />,
  idin: <SvgIdin />,
  'plus-circle': <FiPlusCircle />,
  'finger-print': <MdFingerprint />,
  'face-id': <SvgFaceId />,
  edit: <FiEdit2 />,
  link: <FiLink />,
  'credit-card': <FiCreditCard />,
  desktop: <Desktop />,
  history: <SvgHistory />,
  download: <FiDownload />,
  'information-circle': <IoMdInformationCircleOutline />,
  'question-mark-circle': <IoMdHelpCircleOutline />,
  user: <FiUser />,
  close: <FiX />,
  'file-text': <FiFileText />,
  minus: <FiMinus />,
  spinner: <SvgSpinner />,
  hourglass: <Hourglass />,
  'error-loading': <ErrorLoading />,
  alert: <Alert />,
  hix: <Hix />,
  'fi-check-circle': <FiCheckCircle />,
  'arrow-downs-line': <RiArrowDownSLine />,
  'bizcuit-transparent': <BizcuitLogoTransparent />,
  'fi-alert-triangle': <FiAlertTriangle />,
  'fi-bell': <FiBell />,
  trash: <FiTrash2 />,
  exportDocument: <SvgExportDocument />,
  exclamation: <FaExclamation />,
};

type IconType = keyof typeof IconMap;

interface IconProps<Dynamic extends boolean> extends IconContextProps {
  icon: Dynamic extends true ? string : IconType;
  dynamic?: Dynamic;
  size?: number;
  color?: string;
  fill?: string;
  id?: string | number;
  heightClassName?: string;
  widthClassName?: string;
  style?: React.CSSProperties;
}

const Icon = <Dynamic extends boolean = false>({
  icon,
  dynamic,
  ...props
}: IconProps<Dynamic>): ReactElement => {
  const isIconUrl = /^\/|(http|https):\/\//.test(icon || '');

  if (dynamic && isIconUrl) {
    return <img src={icon} alt={`${icon} icon`} className={props?.className} />;
  }

  const iconComponent = IconMap[icon as IconType] || <></>;

  const allowedProps = ['id', 'size', 'color', 'fill', 'style', 'className'];
  const iconProps = Object.fromEntries(
    Object.entries(props).filter(([key, val]) => allowedProps.includes(key) && val),
  );

  const Icon = () => React.cloneElement(iconComponent, iconProps);

  return (
    <IconContext {...props}>
      <Icon />
    </IconContext>
  );
};

export const BizcuitIcon = memo(Icon);
