import { t } from 'i18next';
import i18n from 'src/i18next';

const getTranslationWithFallback = (
  key: string,
  flowId: string,
  params?: Record<string, string | null>,
) => {
  let translationKey = `${key}.${flowId}`;
  if (!i18n.exists(translationKey)) {
    translationKey = `${key}.default`;
  }
  const translation = t(translationKey, params);

  if (key.includes(translation)) return;
  return translation;
};

export default getTranslationWithFallback;
