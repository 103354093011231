import { Paths } from 'src/tokens';
import { FlowData } from 'src/types/bizcuitApi';
import { Request } from 'src/types/orchestrator';

type GetNextActionStateUrlArgs = {
  nextUrl: string;
  flow: FlowData;
  request?: Request;
  stateParam?: string;
};

/**
 * Generates the next action URL based on the flow and action states.
 *
 * @param {Object} args - The arguments object.
 * @param {string} args.nextUrl - The URL for the next action as returned by `orchestrator.getNextUrls()`.
 * @param {FlowData} args.flow - The flow data containing actions and flow type.
 * @param {Request} [args.request] - The request data containing the request ID.
 * @param {string} [args.stateParam='partnerId'] - The state parameter stored in action params, to be replaced in the URL - default: 'partnerId'.
 * @returns {string} The URL to navigate to the next action.
 */
const getNextActionStateUrl = ({
  nextUrl,
  flow,
  request,
  stateParam = 'partnerId',
}: GetNextActionStateUrlArgs): string => {
  let currentAction = flow.actions.find(
    (action) => !['completed', 'blocked'].includes(action.status),
  );

  if (currentAction?.__typename === 'FlowData') {
    currentAction = currentAction.actions.find(
      (action) => !['completed', 'blocked'].includes(action.status),
    );
  }

  if (currentAction?.status === 'waiting') {
    return `${Paths.orchestrator.root}${Paths.orchestrator.segments.request.root}${Paths.orchestrator.segments.request.segments.status}`.replace(
      ':requestId',
      request?.requestId ?? '',
    );
  }

  if (flow?.flowType === 'freeform' && !flow.isFinished) {
    return (
      Paths.orchestrator.root + Paths.orchestrator.segments.overview.replace(':flowId', flow.id)
    );
  }

  const actionState =
    currentAction?.__typename === 'ActionData' ? JSON.parse(currentAction?.state ?? '{}') : {};

  return `${nextUrl.replace(':' + stateParam, actionState?.[stateParam])}${
    flow.flowType === 'freeform' ? `?flowId=${flow.id}` : ''
  }`;
};

export default getNextActionStateUrl;
