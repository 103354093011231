import { isValid, parse } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import DatePicker from 'react-calendar';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { TextColor } from 'src/atoms/text/text/Text.types';
import TextInput from '../text-input/TextInput';
import DateInputProps from './DateInput.types';
import format from 'date-fns/format';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';

const DateInput: FC<DateInputProps> = ({
  label = 'Datum',
  name = 'date-input',
  placeHolder = 'dd-mm-jjjj',
  errorMessage = 'Voer een juiste datum in',
  subText,
  onValidated,
  className = '',
  onChange,
  value,
  required = false,
}) => {
  const [showChooseDateModal, setShowChooseDateModal] = useState(false);
  const [date, setDate] = useState(value);

  useEffect(() => {
    const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
      if (showChooseDateModal && e.key === 'Escape') {
        setShowChooseDateModal(!showChooseDateModal);
      }
    };
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [showChooseDateModal]);

  useEffect(() => {
    if (!value) return;

    onValidated?.(isValid(parse(value, 'dd-MM-yyyy', new Date())));
  }, [onValidated, value]);

  const onDatePicked = (date: Date) => {
    setShowChooseDateModal(false);

    // TODO: refactor to pass date object instead of a string
    const formattedDate = format(date, 'dd-MM-yyyy');
    setDate(formattedDate);

    onChange(formattedDate);
  };

  return (
    <>
      <TextInput
        label={label}
        name={name}
        className={className}
        placeHolder={placeHolder}
        errorMessage={errorMessage}
        subText={subText}
        suffix={{
          element: (
            <BizcuitIcon icon="calendar" className="w-6 h-6" primaryColor={TextColor.bizGray500} />
          ),
          onClick: () => setShowChooseDateModal(true),
        }}
        value={value ?? date}
        onChange={onChange}
        required={required}
      />
      {showChooseDateModal && (
        <div className="absolute top-6/12 -translate-y-6/12 left-auto right-auto rounded-2xl p-4 bg-biz-gray-100 dark:bg-biz-gray-700 z-20">
          <DatePicker onChange={onDatePicked} />
        </div>
      )}
      <div
        className={`absolute top-0 left-0 w-full h-full flex items-center justify-center${
          showChooseDateModal ? '' : ' hidden'
        }`}
      >
        <div
          className="fixed w-screen h-screen bg-gray-800 dark:bg-gray-600 opacity-40 z-10"
          onClick={() => setShowChooseDateModal(false)}
        />
      </div>
    </>
  );
};

export default DateInput;
