import { useState } from 'react';
import { DemoNames, FlowName } from './OrchestratorFlowDemo.types';
import { defaultDemoPartner, defaultPartnerId, defaultUserData } from './MockData';
import { getExternalId, getPocSetup } from './utils';

export type OrchestratorFlowDemoState = {
  demoPartner: DemoNames;
  externalId: string;
  userId: string;
  partnerId: string;
  themePartnerId: string;
  redirectUrl: string | null;
  renewConsentUrl: string | null;
  flowId: FlowName;
  jwtExpiresSeconds: number;
  email: string;
  restrictedAccountingSystemList: string[];
  bankList: string[];
  pspList: string[];
  bankConnectorList: string[];
  isBusy: boolean;
  termsAccepted: boolean;
  extendJwtDuration: boolean;
  selectBetaPrograms: string[];
  flowParams?: Record<string, string>;
};

export function getOrchestratorFlowDemoInitialState() {
  const initialState: OrchestratorFlowDemoState = {
    demoPartner: defaultDemoPartner,
    externalId: getExternalId(),
    userId: getPocSetup('userId'),
    partnerId: getPocSetup('partnerId'),
    themePartnerId: defaultPartnerId,
    redirectUrl: null,
    renewConsentUrl: null,
    flowId: defaultUserData[defaultDemoPartner].validFlows[0],
    jwtExpiresSeconds: 0,
    email: '',
    restrictedAccountingSystemList: [],
    bankList: [],
    pspList: [],
    bankConnectorList: [],
    isBusy: false,
    termsAccepted: false,
    extendJwtDuration: false,
    selectBetaPrograms: [],
  };

  return initialState;
}

export default function useOrchestratorFlowDemo() {
  const [orchestratorFlowDemoState, setState] = useState(getOrchestratorFlowDemoInitialState());

  function setOrchestratorFlowDemoState(payload: Partial<OrchestratorFlowDemoState>) {
    payload;
    setState((state) => ({
      ...state,
      ...payload,
    }));
  }

  return { orchestratorFlowDemoState, setOrchestratorFlowDemoState };
}
