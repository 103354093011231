import { useLocation } from 'react-router-dom';
import { FormEvent, ReactNode, Reducer, useMemo, useReducer } from 'react';
import FlowLayout from 'src/atoms/layout/menu-page/FlowLayout';
import Select from 'src/atoms/inputs/select/Select';
import TextInput from 'src/atoms/inputs/text-input/TextInput';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import format from 'date-fns/format';
import DateInput from 'src/atoms/inputs/date-input/DateInput';
import {
  MockBankAccountInput,
  useCreateMockBankAccounts,
  useGetBankAccounts,
} from 'src/types/bizcuitApi';
import { useErrorDialog } from 'src/hooks';
import SkeletonLoader from 'src/atoms/loaders/skeleton-loader/SkeletonLoader';

type BankAccountEntry = {
  accountHolderName: string;
  iban: string;
  validUntil: string;
  bankAccountType?: 'NP' | 'ORG' | null;
};

const bankAccountEntriesReducer: Reducer<
  BankAccountEntry[],
  | ({ type: 'create'; index?: number } & BankAccountEntry)
  | { type: 'delete'; index: number }
  | ({ type: 'update'; index: number } & Partial<BankAccountEntry>)
> = (state, action) => {
  switch (action.type) {
    case 'create':
      return [
        ...state,
        {
          accountHolderName: action.accountHolderName || '',
          iban: action.iban.replaceAll('  ', ''),
          validUntil: action.validUntil || '',
          bankAccountType: action.bankAccountType,
        },
      ];

    case 'update': {
      return state.map((account, accountIndex) => {
        const shouldUpdate = accountIndex === action.index;
        if (!shouldUpdate) return account;

        return {
          accountHolderName: action.accountHolderName || account.accountHolderName,
          iban: (action.iban ? action.iban : account.iban).replaceAll('  ', ''),
          validUntil: action.validUntil || account.validUntil,
          bankAccountType: action.bankAccountType === null ? null : action.bankAccountType,
        };
      });
    }

    case 'delete':
      return state.filter((_, accountIndex) => accountIndex !== action?.index);
    default:
      return state;
  }
};

const BankAccountEntry = ({
  index,
  showDelete = false,
  onDelete,
  onChange,
}: {
  index: number;
  showDelete?: boolean;
  onDelete?: (index: number) => void;
  onChange: (args: Partial<BankAccountEntry>) => void;
}) => {
  return (
    <>
      {index > 0 && <hr className="bg-grey-800 dark:border-neutral-500 rounded" />}
      <div className="flex gap-2 items-center">
        <div className="rounded-full bg-primary-500 py-1 px-3">{index + 1}</div>
        <div className="flex flex-col gap-1 items-center">
          <div className="flex gap-1 w-full">
            <TextInput
              name={`userName-${index}`}
              label="Account Holder Name"
              onChange={(value) => {
                onChange({ accountHolderName: value });
              }}
              required
            />
            <TextInput
              name={`iban-${index}`}
              label="IBAN"
              onChange={(value) => {
                onChange({ iban: value });
              }}
              required
            />
          </div>
          <div className="flex gap-1 w-full">
            <DateInput
              name={`validUntil-${index}`}
              onChange={(date: string) => {
                onChange({
                  validUntil: format(new Date(date.split('-').reverse().join('-')), 'yyyy-MM-dd'),
                });
              }}
              onValidated={(isValid) => {
                if (!isValid) {
                  onChange({ validUntil: '' });
                }
              }}
              required
            />
            <Select
              name={`type-${index}`}
              label="Type"
              placeHolder={'Select type'}
              options={[
                { label: 'Empty', value: '' },
                { label: 'Natural Person', value: 'NP' },
                { label: 'Organisation', value: 'ORG' },
              ]}
              onChange={(value) => {
                onChange({ bankAccountType: value.length ? (value as 'NP' | 'ORG') : null });
              }}
            />
          </div>
        </div>
        {showDelete && (
          <PrimaryButton
            className="bg-red-500 px-2  hover:bg-red-800 focus:bg-red-800"
            size="small"
            type="button"
            onClick={() => onDelete?.(index)}
          >
            <BizcuitIcon icon="trash" color="white" />
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

const MockBankLayout = ({
  children,
  footer,
}: {
  children: ReactNode;
  loading?: boolean;
  footer?: ReactNode;
}) => {
  return (
    <FlowLayout
      pageHeaderText={'Mock Bank'}
      showLanguagePicker={false}
      showBackButton={false}
      flowContainer={false}
      footer={footer}
    >
      {children}
    </FlowLayout>
  );
};

const MockBank = () => {
  const { search } = useLocation();
  const [createMockBankAccounts, { loading }] = useCreateMockBankAccounts();
  const errorDialog = useErrorDialog();
  const { data: userBankAccounts, loading: loadingUserBankAccounts } = useGetBankAccounts();
  const { redirectUri } = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    return {
      redirectUri: queryParams.get('redirect_uri'),
    };
  }, [search]);

  const [state, dispatch] = useReducer(bankAccountEntriesReducer, [
    { accountHolderName: '', iban: '', validUntil: '', bankAccountType: undefined },
  ]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await createMockBankAccounts({
        variables: { mockBankAccounts: state as MockBankAccountInput[] },
      });
    } catch (error) {
      console.error('createMockBankAccounts', { error, mockBankAccounts: state });
      errorDialog.open();
      return;
    }

    if (!redirectUri) return;
    window.location.href = decodeURIComponent(redirectUri);
  };

  if (loadingUserBankAccounts) {
    return (
      <MockBankLayout>
        <SkeletonLoader wrapperClassName="mt-7" />
      </MockBankLayout>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <MockBankLayout
        loading={loading}
        footer={
          <div className="mx-auto flex gap-4 py-6">
            <PrimaryButton
              type="button"
              className="mt-4 bg-green-500 focus:bg-green-800"
              showSpinner={loading}
              onClick={() => {
                dispatch({
                  type: 'create',
                  accountHolderName: '',
                  iban: '',
                  validUntil: '',
                  bankAccountType: undefined,
                });
              }}
            >
              + Add
            </PrimaryButton>
            <PrimaryButton
              type="button"
              className="mt-4"
              showSpinner={loading}
              onClick={async () => {
                if (!redirectUri) return;
                await createMockBankAccounts({ variables: { mockBankAccounts: [] } });
                window.location.href = redirectUri;
              }}
            >
              Continue with existing
            </PrimaryButton>
            <PrimaryButton type="submit" className="mt-4 bg-blue-600" showSpinner={loading}>
              Create Bank Accounts
            </PrimaryButton>
          </div>
        }
      >
        {Boolean(userBankAccounts?.getBankAccounts?.length) && (
          <div className="w-full py-12 flex justify-center">
            <div className="w-full">
              <h2 className="text-xl mb-4 text-center font-medium">
                Existing BankAccounts to Renew
              </h2>
              <div className="flex flex-col gap-3">
                {userBankAccounts?.getBankAccounts?.map((bankAccount) => (
                  <div
                    className="rounded w-full bg-neutral-200 py-2 px-3 text-gray-700"
                    key={bankAccount.iban}
                  >
                    {bankAccount.iban}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="w-full py-12 flex justify-center">
          <div className="w-full">
            <h2 className="text-xl mb-4 text-center font-medium">Bank Accounts to Create/Update</h2>
            <div className="flex flex-col gap-3">
              {state.map((_, index) => (
                <BankAccountEntry
                  key={index}
                  index={index}
                  showDelete={state.length > 1}
                  onChange={(args) => {
                    dispatch({ type: 'update', index, ...args });
                  }}
                  onDelete={() => {
                    dispatch({ type: 'delete', index });
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </MockBankLayout>
    </form>
  );
};

export default MockBank;
