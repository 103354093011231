import { Route, Routes } from 'react-router';
import { lazy, Suspense } from 'react';
import Paths from 'src/tokens/Paths';
import FlowLayout from 'src/atoms/layout/menu-page/FlowLayout';
const Introduction = lazy(() => import('./introduction/Introduction'));
const ExistingConnection = lazy(() => import('./existing-connection/ExistingConnection'));
const ConfigureEmailAddresses = lazy(
  () => import('./configure-email-addresses/ConfigureEmailAddresses'),
);
const ConnectionSetUp = lazy(() => import('./connection/ConnectionSetUp'));
const ConfigureConnection = lazy(() => import('./configure-connection/ConfigureConnection'));
const SelectApp = lazy(() => import('./select-app/SelectApp'));
const Connect = lazy(() => import('src/pages/connect-mini-app/connect/Connect'));
const ContactForm = lazy(() => import('./contact-form/IntegrationContactForm'));
const IntegrationContactSuccess = lazy(
  () => import('./integration-contact-success/IntegrationContactSuccess'),
);
const UserPaymentAccounts = lazy(() => import('./payment-account-list/UserPaymentAccounts'));

const { segments } = Paths.miniApp;

const ConnectMiniAppRouter = () => {
  return (
    <Suspense fallback={<FlowLayout />}>
      <Routes>
        <Route path={segments.introduction} element={<Introduction />} />
        <Route path={segments.existingConnection} element={<ExistingConnection />} />
        <Route path={segments.connection} element={<ConnectionSetUp />} />
        <Route path={segments.connect} element={<Connect />} />
        <Route path={segments.configureConnection} element={<ConfigureConnection />} />
        <Route path={segments.configureEmailAddresses} element={<ConfigureEmailAddresses />} />
        <Route path={segments.selectApp} element={<SelectApp />} />
        <Route path={segments.contactForm} element={<ContactForm />} />
        <Route path={segments.contactIntegrationSuccess} element={<IntegrationContactSuccess />} />
        <Route path={segments.userPaymentAccounts} element={<UserPaymentAccounts />} />
      </Routes>
    </Suspense>
  );
};

export default ConnectMiniAppRouter;
