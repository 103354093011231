import { LabelProps } from './Label.types';

const Label = ({ children, className = '', type }: LabelProps) => {
  let typeColors: string;

  switch (type) {
    case 'yellow':
      typeColors = 'bg-biz-yellow-100 text-biz-yellow-800';
      break;
    case 'red':
      typeColors = 'bg-biz-red-100 text-biz-red-800';
      break;
    default:
      typeColors = 'bg-biz-green-100 text-green-800';
      break;
  }

  return (
    <div className={`px-2 py-0.5 font-weight-500 text-x-small rounded ${typeColors} ${className}`}>
      {children}
    </div>
  );
};

export default Label;
