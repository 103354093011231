import React, { FC } from 'react';
import SecondaryButtonProps from './SecondaryButton.types';
import { ComponentTestIdKeys } from 'src/tokens';

const SecondaryButton: FC<SecondaryButtonProps> = ({
  color = 'text-biz-gray-800',
  underline = false,
  children,
  onClick,
  isDisabled,
  testId,
  className,
  ...rest
}) => (
  <button
    data-testid={testId ? `${ComponentTestIdKeys.SecondaryButton}-${testId}` : ''}
    className={`cursor-pointer w-full text-sm dark:text-gray-100 text-center font-weight-500 mb-5 focus:outline-none focus:underline${
      underline ? ' underline' : ''
    }${isDisabled ? ' inactive' : ' active'} ${color}${className ? ` ${className}` : ''}`}
    onClick={onClick}
    disabled={isDisabled}
    {...rest}
  >
    {children}
  </button>
);

export default SecondaryButton;
