import React, { FC, useState, ReactNode } from 'react';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';

type AccordionProps = {
  title?: { value?: string; isBold?: boolean };
  children: ReactNode;
  isBoldTitle?: boolean;
  summaryDescription?: string;
  collapsed?: boolean;
};

const Accordion: FC<AccordionProps> = ({ title, children, summaryDescription, collapsed }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed ? collapsed : false);
  return (
    <div
      className={
        'rounded-2xl shadow-md bg-white dark:bg-biz-gray-700 p-4 relative transform-gpu transition-all w-full'
      }
    >
      <div
        className={`absolute right-4 transform-gpu transition-all top-4 ${
          isCollapsed ? '' : 'rotate-180'
        }`}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <BizcuitIcon
          icon={'arrow-downs-line'}
          className="text-gray-700 dark:text-white"
          size={28}
        />
      </div>
      <p className="text-small-loose font-weight-500 text-gray-700 dark:text-white">
        {title?.value}
      </p>
      {isCollapsed ? (
        <p className="text-x-small-loose truncate mt-3 text-warm-gray-700 dark:text-white">
          {summaryDescription}
        </p>
      ) : (
        <p className="text-x-small-loose mt-3 text-warm-gray-700 dark:text-white">{children}</p>
      )}
    </div>
  );
};

export default Accordion;
