import PrimaryHeader from '../../text/primary-header/PrimaryHeader';
import React, { FC } from 'react';

const SkeletonUboFormLoader: FC = () => (
  <div className="flex flex-col gap-2 animate-pulse">
    <PrimaryHeader className="mb-2 w-3/4 h-4 rounded-xl bg-primary-200 dark:bg-primary-800 cursor-default" />
    <div className="flex justify-between items-center mt-6 m-4">
      <div className="w-1/3 h-3 rounded-full bg-primary-200 dark:bg-primary-800 mr-2" />
      <div className="w-1/4 h-2 bg-primary-200 dark:bg-primary-800 rounded-2xl" />
    </div>
    <div className="w-1/6 h-3 rounded-full bg-primary-200 dark:bg-primary-800 mr-2 mb-4" />
    <div className="grid md:grid-cols-2 gap-4 md:gap-2">
      <div className="pt-5 bg-biz-gray-100 dark:bg-biz-gray-700 rounded-lg-plus h-15 w-full" />
      <div className="pt-5 bg-biz-gray-100 dark:bg-biz-gray-700 rounded-lg-plus h-15 w-full" />
      <div className="pt-5 bg-biz-gray-100 dark:bg-biz-gray-700 rounded-lg-plus h-15 w-full" />
    </div>
    <div className="flex flex-col mt-4 gap-4 md:gap-2">
      <div className="pt-5 bg-biz-gray-100 dark:bg-biz-gray-700 rounded-lg-plus h-15 w-full" />
      <div className="pt-5 bg-biz-gray-100 dark:bg-biz-gray-700 rounded-lg-plus h-15 w-full" />
    </div>
    <div className="border border-gray-20 dark:border-gray-800 my-8" />
    <div className="flex flex-col gap-4">
      <div className="w-1/3 h-3 rounded-full bg-primary-200 dark:bg-primary-800 mr-2 mb-4" />
      <div className="flex items-center gap-4">
        <div className="w-5 h-5 shadow-inner rounded mr-2 bg-biz-gray-600" />
        <div className="w-1/3 h-3 rounded-full bg-primary-200 dark:bg-primary-800 mr-2" />
      </div>
    </div>
  </div>
);

export default SkeletonUboFormLoader;
