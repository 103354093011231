import { FC, FocusEvent, useState } from 'react';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { TextColor } from 'src/atoms/text/text/Text.types';
import TextInputProps from './TextInput.types';
import { ComponentTestIdKeys } from 'src/tokens';

const TextInput: FC<TextInputProps> = ({
  placeHolder,
  label,
  name,
  type = 'text',
  value,
  subText,
  isDisabled,
  errorMessage,
  showError,
  prefixImgSrc,
  clearFieldSuffix,
  suffix,
  focusOnRender,
  onBlur,
  className,
  valueLowerCased = false,
  testId,
  min,
  max,
  isValid,
  required,
  loading = false,
  ...props
}) => {
  const [textValue, setTextValue] = useState(value || '');
  const [focused, setFocused] = useState(focusOnRender ?? false);

  const invalid = isValid !== undefined && !isValid;
  const shouldErrorBeShown = invalid && showError;
  const onChange = (value: string) => {
    setTextValue(value);
    props.onChange && props.onChange(value);
  };

  const onInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (!placeHolder) return;
    if (textValue.length <= 0) return;

    event.target.placeholder = placeHolder;
  };

  const onInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(textValue);
    setFocused(false);
    event.target.placeholder = ' ';
  };

  const labelClasses =
    focused || textValue.length > 0 || (value && value.length > 0)
      ? `${
          shouldErrorBeShown ? ' text-biz-red-500' : ' text-primary-500'
        } font-weight-400 text-xs -translate-y-3`
      : `${
          shouldErrorBeShown ? ' text-biz-red-500' : ' dark:text-biz-gray-200 text-biz-gray-600'
        } font-weight-500 text-base `;

  return (
    <div className={`w-full relative${className?.length ? ` ${className}` : ''}`}>
      {prefixImgSrc && (
        <img className="absolute top-5 left-3 w-6 h-6" src={prefixImgSrc} alt="prefix-icon" />
      )}
      <input
        id={name}
        className={`pt-5 bg-biz-gray-100 dark:bg-biz-gray-700 rounded-lg-plus font-weight-500 text-base text-primary-900 dark:text-biz-gray-100 h-15 w-full focus:outline-none${
          prefixImgSrc ? ' px-11' : ' px-4'
        }${shouldErrorBeShown ? ' border border-solid border-biz-red-500' : ''}${
          valueLowerCased ? ' lowercase' : ''
        }`}
        type={type}
        name={name}
        value={value || textValue}
        disabled={isDisabled}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        autoFocus={focusOnRender}
        min={min}
        max={max}
        data-testid={testId ? `${ComponentTestIdKeys.TextInput}-${testId}` : ''}
        required={required}
      />
      {clearFieldSuffix && textValue.length > 0 && (
        <div
          className="absolute top-5 right-3 cursor-pointer"
          role="button"
          aria-busy={loading}
          onClick={() => onChange('')}
        >
          {loading ? (
            <BizcuitIcon icon="spinner" className="w-6 h-6 animate-spin" />
          ) : (
            <BizcuitIcon icon="x-circle" className="w-6 h-6" primaryColor={TextColor.bizGray500} />
          )}
        </div>
      )}
      {!clearFieldSuffix && suffix && (
        <div className="text-biz-gray-500 absolute top-5 right-3" onClick={suffix.onClick}>
          {suffix.element}
        </div>
      )}
      <label
        className={`absolute top-5 truncate max-w-[calc(100%-2rem)] transition-all transform-gpu${
          prefixImgSrc ? ' left-11' : ' left-4'
        }${labelClasses}`}
        htmlFor={name}
      >
        {label}
      </label>
      {shouldErrorBeShown && (
        <>
          {subText && (
            <p className="text-xs-plus font-weight-500 py-2 px-3 text-biz-gray-600 dark:text-biz-gray-400">
              {subText}
            </p>
          )}
          {errorMessage && (
            <p className="text-xs-plus font-weight-500 py-2 px-3 text-biz-red-500">
              {errorMessage}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default TextInput;
