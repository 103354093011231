import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowLayout } from 'src/atoms/layout/menu-page';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import RadioContainer from 'src/atoms/inputs/radio-container/RadioContainer';
import {
  GetUserBankAccountsAndCustomers,
  useGetUserBankAccountsAndCustomers,
  useSetIsCompanyPerCustomer,
} from 'src/types/bizcuitApi';
import SkeletonSelectAccountTypeLoader from 'src/atoms/loaders/skeleton-loader/SkeletonSelectAccountTypeLoader';
import { useOrchestrator } from 'src/hooks/useOrchestrator';

interface AccountType {
  customerId: string;
  isCompany: boolean;
}

const SelectAccountTypeLayout: FC<{
  canContinue?: boolean;
  isSubmitting?: boolean;
  children: ReactNode;
}> = ({ canContinue = false, isSubmitting = false, children = <></> }) => {
  const { t } = useTranslation(['cdd', 'common']);

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      showBackButton={false}
      spaceBetween={false}
      footer={
        <footer className="flex justify-center pb-6">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          <PrimaryButton
            className="md:w-80 mt-4 md:mt-6"
            type="submit"
            form="select-account-type"
            showSpinner={isSubmitting}
            isDisabled={canContinue}
          >
            {t('common:button.confirm')}
          </PrimaryButton>
        </footer>
      }
    >
      <>
        <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
          {t('cdd:selectAccountType.primaryHeader')}
        </h1>
        {children}
      </>
    </FlowLayout>
  );
};

const SelectAccountType = () => {
  const { t } = useTranslation(['cdd', 'common']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [indicatedAccountTypes, setIndicatedAccountTypes] = useState<AccountType[]>([]);
  const orchestrator = useOrchestrator();
  const [setIsCompanyPerCustomer] = useSetIsCompanyPerCustomer();

  const getUserBankAccountsAndCustomers = useGetUserBankAccountsAndCustomers({
    fetchPolicy: 'cache-and-network',
  });

  const groupedBankAccountList = useMemo(() => {
    if (!getUserBankAccountsAndCustomers.data?.getBankAccounts) return {};

    const { getBankAccounts: userBankAccounts, getCustomers: userCustomers } =
      getUserBankAccountsAndCustomers.data;

    if (!userCustomers?.length) return {};

    return userCustomers.reduce((previousValue, customer) => {
      const customerBankAccounts = userBankAccounts.filter(
        (bankAccount) =>
          bankAccount.customerId === customer?.id && bankAccount.companyAccount === null,
      );

      if (!customer?.name || !customerBankAccounts.length) return previousValue;

      previousValue[customer.name] = customerBankAccounts;

      return previousValue;
    }, {} as Record<string, NonNullable<GetUserBankAccountsAndCustomers['getBankAccounts']>>);
  }, [getUserBankAccountsAndCustomers.data]);
  const isLoading = !Object.keys(groupedBankAccountList).length;

  const handleConfirm = async () => {
    setIsSubmitting(true);

    const result = await setIsCompanyPerCustomer({
      variables: { isCompanyPerCustomer: indicatedAccountTypes },
    });

    if (result.errors || !result.data?.setIsCompanyPerCustomer) {
      setIsSubmitting(false);
      return;
    }

    await orchestrator.methods.finishFlowAction({ actionId: 'select-account-type' });
    setIsSubmitting(false);
  };

  const handleTypeSelection = ({
    customerId,
    isCompany,
  }: {
    customerId: string;
    isCompany: boolean;
  }) => {
    setIndicatedAccountTypes((prevState) => {
      if (prevState.some((item) => item.customerId === customerId)) {
        return prevState.map((item) =>
          item.customerId === customerId ? { ...item, isCompany: isCompany } : item,
        );
      }

      return [...prevState, { customerId, isCompany: isCompany }];
    });
  };

  if (isLoading)
    return (
      <SelectAccountTypeLayout>
        <SkeletonSelectAccountTypeLoader />
      </SelectAccountTypeLayout>
    );

  return (
    <form
      id="select-account-type"
      onSubmit={(e) => {
        e.preventDefault();
        handleConfirm();
      }}
    >
      <SelectAccountTypeLayout
        canContinue={Object.keys(groupedBankAccountList).length !== indicatedAccountTypes.length}
        isSubmitting={isSubmitting}
      >
        {Object.entries(groupedBankAccountList).map(([title, bankAccounts], index) => {
          return (
            <div key={index}>
              <div className="flex flex-col gap-4 mb-6">
                <div>
                  <p className="text-medium font-weight-500 text-grey-900 dark:text-white">
                    {title}
                  </p>
                  {bankAccounts.slice(0, 3).map((account, index) => (
                    <p key={index} className="text-x-small text-grey-700 dark:text-white">
                      {account.iban}
                    </p>
                  ))}
                  {bankAccounts.length > 3 && (
                    <p className="text-x-small text-grey-700 dark:text-white">...</p>
                  )}
                </div>
                <div className="grid md:grid-cols-2 gap-2">
                  <RadioContainer
                    name={`business-${bankAccounts[0].customerId}`}
                    value="business"
                    isChecked={
                      indicatedAccountTypes.find(
                        (item) => item.customerId === bankAccounts[0].customerId,
                      )?.isCompany === true
                    }
                    onSelected={() =>
                      handleTypeSelection({
                        customerId: bankAccounts[0].customerId ?? '',
                        isCompany: true,
                      })
                    }
                  >
                    <p>{t('common:business')}</p>
                  </RadioContainer>
                  <RadioContainer
                    name={`private-${bankAccounts[0].customerId}`}
                    value="private"
                    isChecked={
                      indicatedAccountTypes.find(
                        (item) => item.customerId === bankAccounts[0].customerId,
                      )?.isCompany === false
                    }
                    onSelected={() =>
                      handleTypeSelection({
                        customerId: bankAccounts[0].customerId ?? '',
                        isCompany: false,
                      })
                    }
                  >
                    <p>{t('common:private')}</p>
                  </RadioContainer>
                </div>
              </div>
              {Object.keys(groupedBankAccountList).length > index + 1 && (
                <div className="w-full border border-grey-200 dark:border-gray-800 mb-6" />
              )}
            </div>
          );
        })}
      </SelectAccountTypeLayout>
    </form>
  );
};

export default SelectAccountType;
