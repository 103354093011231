import { FlowLayout } from 'src/atoms/layout/menu-page';
import SupportContainer from 'src/molecules/layout/support-container/SupportContainer';
import Accordion from 'src/organisms/accordion/Accordion';
import React, { useState } from 'react';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import AlertDialog from 'src/organisms/dialog/AlertDialog';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Paths from 'src/tokens/Paths';
import { useGetCustomersAndUbos } from 'src/types/bizcuitApi';
import { parseQueryParamsString } from 'src/helpers';
import SkeletonCompanyLoader from 'src/atoms/loaders/skeleton-loader/SkeletonCompanyLoader';
import { useErrorDialog } from 'src/hooks';

const ProcessOverview = () => {
  const { dynamicId } = parseQueryParamsString(location.search);
  const { data, loading, error } = useGetCustomersAndUbos();
  const [showPseudoUboAlert, setShowPseudoUboAlert] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(['cdd', 'common']);
  const errorDialog = useErrorDialog();

  const whenAnUboPoints = t('cdd:whatIsAnUbo.description.moreThan25Percent', {
    returnObjects: true,
  });

  if (error) {
    errorDialog.open();
  }
  const customer = data?.getCustomers?.find((customer) => customer.id === dynamicId);

  const copyUbosFromCompany =
    data && data.getCustomers?.length >= 3 && data.getCustomers?.some((customer) => customer?.ubos);

  const handleRedirect = (type: 'ubo' | 'pseudo-ubo') => {
    const basePath = `${Paths.ais.root}${Paths.ais.segments.cdd.root}`;

    // TODO: implement ubosFromDirector to make redirect work as it should
    const path = copyUbosFromCompany
      ? `${basePath}${Paths.ais.segments.cdd.segments.ubosFromAnotherCompany}?dynamicId=${dynamicId}`
      : `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.ubosInProgress}?dynamicId=${dynamicId}`;

    navigate(path.replace(':type', type));
  };
  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex pb-6 items-center w-full flex-wrap flex-col">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          <SecondaryButton
            underline
            onClick={() => setShowPseudoUboAlert(true)}
            className="mt-4 md:mt-6"
            isDisabled={loading}
          >
            {t('cdd:processOverview.secondaryButton')}
          </SecondaryButton>
          <PrimaryButton
            className="md:w-80"
            onClick={() => handleRedirect('ubo')}
            isDisabled={loading}
          >
            {t('cdd:processOverview.primaryButton')}
          </PrimaryButton>
        </footer>
      }
    >
      <>
        <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
          {t('cdd:processOverview.primaryHeader')}
        </h1>
        <div className="flex flex-col">
          <p className="text-medium-loose mb-6">{t('cdd:processOverview.description')}</p>
          {loading ? (
            <SkeletonCompanyLoader />
          ) : (
            <div className="flex p-4 mb-6 justify-between">
              <p className="text-medium font-weight-500">{customer?.name}</p>
              <div className="flex gap-1 text-x-small items-center">
                <p className="font-weight-500">{t('common:chamberOfCommerceShortHand')}</p>
                <p>{customer?.kvkNumber}</p>
              </div>
            </div>
          )}
          <div className="mb-6">
            <Accordion
              title={{
                value: t(`cdd:whatIsAnUbo.title`),
                isBold: true,
              }}
              summaryDescription={t('cdd:whatIsAnUbo.description.uboDescription')}
              collapsed
            >
              <div>
                <p>{t('cdd:whatIsAnUbo.description.uboDescription')}</p>
                <ul className="list-disc px-4 py-6">
                  {Object.values(whenAnUboPoints).map((translation, index) => (
                    <li key={index}>{translation}</li>
                  ))}
                </ul>
                <p>{t('cdd:whatIsAnUbo.description.noOneQualifies')}</p>
              </div>
            </Accordion>
          </div>
          <SupportContainer />
        </div>
      </>
      <AlertDialog
        isVisible={showPseudoUboAlert}
        onConfirm={() => {
          handleRedirect('pseudo-ubo');
        }}
        onClose={() => setShowPseudoUboAlert(!showPseudoUboAlert)}
        alertDialogTexts={t('cdd:processOverview.alertDialog', { returnObjects: true })}
        primaryColorContinueButton
      />
    </FlowLayout>
  );
};

export default ProcessOverview;
