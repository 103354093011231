import React, { FC, useRef, useState } from 'react';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import SelectProps, { OptionProps } from './Select.types';
import { useOnClickOutside } from 'src/hooks';

const Select: FC<SelectProps> = ({ label, name, className, options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OptionProps>({ label: '', value: '' });

  const onOptionClicked = (value: OptionProps) => () => {
    setSelectedOption(value);
    onChange?.(value.value);
    setIsOpen(false);
  };

  const toggling = () => setIsOpen(!isOpen);
  const optionsPanelRef = useRef(null);

  useOnClickOutside(optionsPanelRef, () => setIsOpen(false));

  const selected = options.find((option) => option.selected);

  return (
    <div className={'w-full relative ' + (className || '')}>
      <button
        id={name}
        className={`${
          isOpen
            ? 'rounded-t-lg-plus text-biz-gray-600'
            : 'rounded-lg-plus  text-warm-grey-800 dark:text-biz-gray-100'
        } flex items-center justify-between bg-biz-gray-100 dark:bg-biz-gray-700 shadow-biz font-weight-500 text-base h-15 w-full focus:outline-none px-3`}
        name={name}
        type="button"
        onClick={toggling}
      >
        <span className="mr-4 mt-2">
          {selectedOption.label || selected?.label || options[0]?.label}
        </span>
        <BizcuitIcon
          icon={isOpen ? 'chevron-up' : 'chevron-down'}
          className="w-5 h-5 text-gray-800 dark:text-white"
        />
      </button>
      {isOpen && (
        <div
          ref={optionsPanelRef}
          className="absolute right-0 py-2 px-1 bg-biz-gray-100 dark:bg-biz-gray-700 rounded-b-md shadow-xl w-full z-10"
        >
          {options.map((option) => (
            <div
              key={option.value + option.label}
              onClick={onOptionClicked({ label: option.label, value: option.value })}
              className="block bg-biz-gray-100 dark:bg-biz-gray-700 text-warm-grey-800 hover:text-warm-grey-600 dark:text-biz-gray-100 hover:dark:text-biz-gray-600 font-weight-500 text-base p-2 hover:cursor-pointer"
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
      <label
        className={`${
          isOpen ? 'text-primary-500' : 'text-biz-gray-600'
        } absolute top-5 transition-all transform-gpu left-3 font-weight-400 text-xs -translate-y-3`}
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
};

export default Select;
