import React from 'react';
import { useNavigate } from 'react-router';
import { usePartnerStore, useUserStore } from 'src/hooks';
import HeadMeta from 'src/molecules/head-meta/HeadMeta';
import HeaderGoBackButton from 'src/atoms/buttons/header-go-back-button/HeaderGoBackButton';
import HeaderProps from './PageHeader.types';
import LanguagePicker from 'src/atoms/language-picker/LanguagePicker';
import { useUpdateUser } from 'src/types/bizcuitApi';
import { useTranslation } from 'react-i18next';
import nlFlag from 'src/assets/images/nl-flag.png';
import gbFlag from 'src/assets/images/gb-flag.png';

const PageHeader: React.FC<HeaderProps> = ({
  title,
  prefix,
  suffix,
  isModal = false,
  showBackButton = true,
  backToUrl,
  children,
  showLanguagePicker = false,
}) => {
  const navigate = useNavigate();
  const { state: partnerState } = usePartnerStore();
  const { state: userState } = useUserStore();
  const [updateUser] = useUpdateUser();
  const { i18n } = useTranslation();

  const getPrefix = () => {
    if (prefix) {
      return <div onClick={prefix.onClick}>{prefix.element}</div>;
    }
    if (showBackButton && window.history.length > 1) {
      return (
        <HeaderGoBackButton onClick={() => (backToUrl ? navigate(backToUrl) : navigate(-1))} />
      );
    }
    return <div />;
  };

  const getSuffix = () => {
    if (suffix) {
      return <div onClick={suffix.onClick}>{suffix.element}</div>;
    }
    return <div />;
  };

  return (
    <>
      <HeadMeta title={title} />
      <header
        className={`flex-self flex-start ${
          isModal
            ? 'px-6 border-b border-biz-gray-200 bg-white'
            : 'px-5 md:px-9 z-40 dark:bg-biz-gray-900'
        } `}
      >
        <div
          className={`flex flex-row justify-between items-center${
            isModal ? ' h-14' : ' md:h-14 h-12'
          }`}
        >
          <div className="flex flex-row items-center w-[20%]">{getPrefix()}</div>
          <div className={`text-base font-weight-600 dark:text-white text-center`}>
            <h1 className={`text-primary-400 dark:text-grey-100`}>
              {title || partnerState.partner?.appName}
            </h1>
          </div>
          <div className="flex flex-row items-center justify-end gap-1 w-[20%]">
            {showLanguagePicker && (
              <LanguagePicker
                defaultLanguage={i18n.language}
                languages={[
                  { flag: nlFlag, language: 'nl' },
                  { flag: gbFlag, language: 'en' },
                ]}
                onLanguageChange={(language) => {
                  i18n.changeLanguage(language);
                  if (userState?.user?.language === language) return;
                  updateUser({ variables: { updateUserArgs: { language } } });
                }}
              />
            )}
            {getSuffix()}
          </div>
        </div>
      </header>
      <div className={`px-9 lg:ml-20 ${children ? 'sm:shadow-md' : ''}`}>{children}</div>
    </>
  );
};

export default PageHeader;
