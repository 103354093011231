import { FlowLayout } from 'src/atoms/layout/menu-page';
import React, { useEffect, useState } from 'react';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import AlertDialog from 'src/organisms/dialog/AlertDialog';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'src/atoms/buttons/tertiary-button/TertiaryButton';
import TextInput from 'src/atoms/inputs/text-input/TextInput';
import Checkbox from 'src/atoms/inputs/checkbox/Checkbox';
import DateInput from 'src/atoms/inputs/date-input/DateInput';
import Select from 'src/atoms/inputs/select/Select';
import { useCreateOrUpdateUbo, useGetCustomers, useGetUboById } from 'src/types/bizcuitApi';
import * as Sentry from '@sentry/react';
import { useErrorDialog } from 'src/hooks';
import { parseQueryParamsString } from 'src/helpers';
import { useLocation } from 'react-router-dom';
import format from 'date-fns/format';
import { parse } from 'date-fns';
import SkeletonUboFormLoader from 'src/atoms/loaders/skeleton-loader/SkeletonUboFormLoader';
import Paths from 'src/tokens/Paths';
import { useNavigate } from 'react-router';

const UboForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { dynamicId, uboId } = parseQueryParamsString(location.search);
  const errorDialog = useErrorDialog();

  const { data: customersData, loading: customersDataLoading } = useGetCustomers();
  const [createOrUpdateUbo, { loading: createOrUpdateLoading }] = useCreateOrUpdateUbo();
  const {
    data: uboData,
    loading: uboLoading,
    error: uboError,
  } = useGetUboById({ skip: !uboId, variables: { uboId: uboId }, fetchPolicy: 'network-only' });
  if (uboError) {
    Sentry.captureException('Get ubo by id failed');
    errorDialog.open();
  }

  const [showAlert, setShowAlert] = useState({
    whatIsUbo: false,
    nobodyHasMoreThan25Percent: false,
    deleteUbo: false,
  });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    nationality: 'NL',
    countryOfResidence: 'NL',
    hasAuthority: false,
    ownershipPercentage: '',
    votingPercentage: '',
  });

  useEffect(() => {
    if (uboData?.getUboById) {
      setFormData({
        firstName: uboData.getUboById.firstName || '',
        lastName: uboData.getUboById.lastName || '',
        dateOfBirth:
          (uboData.getUboById.dateOfBirth &&
            format(
              parse(uboData.getUboById.dateOfBirth, 'yyyy-MM-dd', new Date()),
              'dd-MM-yyyy',
            )) ||
          '',
        nationality: uboData.getUboById.nationality || 'NL',
        countryOfResidence: uboData.getUboById.countryOfResidence || 'NL',
        hasAuthority: uboData.getUboById.hasAuthority || false,
        ownershipPercentage: uboData.getUboById.ownershipPercentage?.toString() || '',
        votingPercentage: uboData.getUboById.votingPercentage?.toString() || '',
      });
    }
  }, [uboData]);

  const [birthDateValid, setBirthDateValid] = useState(false);

  const { t } = useTranslation(['cdd', 'common']);

  //TODO: Replace with actual data and ubo type
  const customer = customersData?.getCustomers.find((customer) => customer.id === dynamicId);
  const uboType = 'ubo';

  const countryOptions = [
    { label: 'Nederland', value: 'NL' },
    { label: 'Belgie', value: 'BE' },
    { label: 'Duitsland', value: 'DE' },
  ];

  const handleSubmit = async () => {
    const { errors } = await createOrUpdateUbo({
      variables: {
        customerId: dynamicId,
        ...(uboId && { uboId: uboId }),
        isPseudoUbo: uboType !== 'ubo',
        uboDetails: {
          ...formData,
          dateOfBirth: format(parse(formData.dateOfBirth, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd'),
          ownershipPercentage: parseInt(formData.ownershipPercentage, 10) || 0,
          votingPercentage: parseInt(formData.votingPercentage, 10) || 0,
        },
      },
    });

    if (errors) {
      Sentry.captureException('Create/update ubo failed');

      errorDialog.open();
      return;
    }

    navigate(
      `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.ubosInProgress}?dynamicId=${dynamicId}`,
    );
  };

  const isDisabled = () => {
    const valuesNotSet = Object.entries(formData).some(([key, value]) => {
      return value === '' && key !== 'ownershipPercentage' && key !== 'votingPercentage';
    });

    const percentagesBelow25 =
      (!formData.ownershipPercentage || parseInt(formData.ownershipPercentage, 10) < 25) &&
      (!formData.votingPercentage || parseInt(formData.votingPercentage, 10) < 25);

    return valuesNotSet || (percentagesBelow25 && !formData.hasAuthority);
  };

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex py-6 items-center w-full flex-wrap flex-col gap-1">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          {uboType === 'ubo' && (
            <p className="text-x-small-loose text-center text-grey-500">
              {t('cdd:uboForm.uboRequirements')}
            </p>
          )}
          <PrimaryButton
            className="md:w-80"
            type="submit"
            form="ubo-form"
            isDisabled={isDisabled() || createOrUpdateLoading || !birthDateValid}
            showSpinner={createOrUpdateLoading}
          >
            {t(`cdd:uboForm.primaryButton`)}
          </PrimaryButton>
        </footer>
      }
    >
      {uboLoading || customersDataLoading ? (
        <SkeletonUboFormLoader />
      ) : (
        <>
          <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
            {t(`cdd:uboForm.${uboType}.primaryHeader`)}
          </h1>
          <div className="flex mb-6 p-4 justify-between">
            <p className="text-medium font-weight-500">{customer?.name}</p>
            <div className="flex gap-1 text-x-small items-center">
              <p className="font-weight-500">{t('common:chamberOfCommerceShortHand')}</p>
              <p>{customer?.kvkNumber}</p>
            </div>
          </div>
          <div className="flex flex-col">
            <TertiaryButton
              className="mb-4"
              color="gray-800"
              underline
              onClick={() => setShowAlert((prevState) => ({ ...prevState, whatIsUbo: true }))}
            >
              {t('common:helpNeededFillingOut')}
            </TertiaryButton>

            <form
              id="ubo-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="grid md:grid-cols-2 gap-4 md:gap-2">
                <TextInput
                  label={t('common:name')}
                  placeHolder={t('common:typeName')}
                  name="firstName"
                  value={formData.firstName}
                  onChange={(value) => {
                    setFormData((prevState) => ({ ...prevState, firstName: value }));
                  }}
                />
                <TextInput
                  label={t('common:surname')}
                  placeHolder={t('common:typeSurname')}
                  name="lastName"
                  value={formData.lastName}
                  onChange={(value) => {
                    setFormData((prevState) => ({ ...prevState, lastName: value }));
                  }}
                />
                <DateInput
                  name="dateOfBirth"
                  label={t('common:dateOfBirth')}
                  showError={!birthDateValid}
                  onValidated={setBirthDateValid}
                  onChange={(value) => {
                    setFormData((prevState) => ({ ...prevState, dateOfBirth: value }));
                  }}
                  value={formData.dateOfBirth}
                />
              </div>
              <div className="flex flex-col mt-4 gap-4 md:gap-2">
                <Select
                  name="nationality"
                  label={t('common:nationality')}
                  placeHolder=""
                  options={countryOptions}
                  onChange={(value) =>
                    setFormData((prevState) => ({ ...prevState, nationality: value }))
                  }
                />
                <Select
                  name="countryOfResidence"
                  label={t('common:livingIn')}
                  placeHolder=""
                  options={countryOptions}
                  onChange={(value) =>
                    setFormData((prevState) => ({ ...prevState, countryOfResidence: value }))
                  }
                />
              </div>
              <div className="border border-gray-20 dark:border-gray-800 my-8" />
              <div>
                {uboType === 'ubo' && (
                  <div className="flex flex-col gap-4">
                    <p className="text-medium-loose text-gray-800 dark:text-white">
                      {t('cdd:uboForm.fillInWhatsApplicable')}
                    </p>
                    <div className="flex gap-4">
                      <Checkbox
                        name="hasAuthority"
                        onChecked={() =>
                          setFormData((prevState) => ({ ...prevState, hasAuthority: true }))
                        }
                        onUnchecked={() =>
                          setFormData((prevState) => ({ ...prevState, hasAuthority: false }))
                        }
                        isChecked={formData.hasAuthority}
                      />
                      <p className="text-medium text-gray-800 dark:text-white">
                        {t('cdd:uboForm.personHasActualControl')}
                      </p>
                    </div>
                    {!formData.hasAuthority && (
                      <div className="grid md:grid-cols-2 gap-4 md:gap-2">
                        <TextInput
                          placeHolder={t('cdd:uboForm.ownershipPercentage')}
                          label={t('cdd:uboForm.ownershipPercentage')}
                          name="ownershipPercentage"
                          type="number"
                          min={0}
                          max={100}
                          value={formData.ownershipPercentage}
                          onChange={(value) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              ownershipPercentage: value,
                            }))
                          }
                        />
                        <TextInput
                          placeHolder={t('cdd:uboForm.votingRightsPercentage')}
                          label={t('cdd:uboForm.votingRightsPercentage')}
                          name="votingPercentage"
                          type="number"
                          min={0}
                          max={100}
                          value={formData.votingPercentage}
                          onChange={(value) =>
                            setFormData((prevState) => ({
                              ...prevState,
                              votingPercentage: value,
                            }))
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </form>
          </div>
        </>
      )}
      <AlertDialog
        isVisible={showAlert.whatIsUbo}
        onClose={() =>
          setShowAlert((prevstate) => ({ ...prevstate, whatIsUbo: !showAlert.whatIsUbo }))
        }
        alertDialogTexts={t(`cdd:whatIsUboDialog.${uboType}`, { returnObjects: true })}
      />
      <AlertDialog
        isVisible={showAlert.deleteUbo}
        onClose={() =>
          setShowAlert((prevstate) => ({
            ...prevstate,
            deleteUbo: !showAlert.deleteUbo,
          }))
        }
        alertDialogTexts={t('cdd:deleteUboDialog', { returnObjects: true })}
      />
      <AlertDialog
        isVisible={showAlert.nobodyHasMoreThan25Percent}
        onClose={() =>
          setShowAlert((prevState) => ({ ...prevState, nobodyHasMoreThan25Percent: false }))
        }
        alertDialogTexts={t('cdd:processOverview.alertDialog', { returnObjects: true })}
        primaryColorContinueButton
      />
    </FlowLayout>
  );
};

export default UboForm;
